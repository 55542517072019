import { IReviewFragment } from 'lib/reviewinc';
import { ReactElement } from 'react';
import Carousel from 'components/UI/Carousel';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';

export type ReviewTestimonialListProps = {
  reviews: IReviewFragment[];
  title: string;
};

export default function ReviewCarousel({
  title,
  reviews,
}: ReviewTestimonialListProps): ReactElement {
  const carouselItems = reviews.map(
    ({ fields: { reviewerShortname, reviewerLocation, testimonial } }, index) => {
      return (
        <div className="text-center" key={index}>
          <div className="mb-2 px-2">{documentToPlainTextString(testimonial)}</div>
          <div className="mb-2 px-2">
            {' '}
            - {reviewerShortname} - {reviewerLocation}
          </div>
        </div>
      );
    }
  );

  return <Carousel title={title} items={carouselItems} style="carousel-reviews" />;
}
